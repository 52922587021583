// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
  }
  
  .reset-password-card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    border: 1px solid #f0f0f0;
  }
  
  .logo-container {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .company-logo {
    width: 120px;
    height: auto;
  }
  
  .title {
    margin-bottom: 1rem;
    color: #333333;
  }
  
  .description {
    color: #555555;
    margin-bottom: 1.5rem;
  }
  
  .form {
    text-align: left;
  }
  
  .submit-button {
    width: 100%;
    background: #1890ff;
    border: none;
    color: #ffffff;
    font-weight: bold;
  }
  
  .submit-button:hover {
    background: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".reset-password-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .reset-password-card {\r\n    background: #ffffff;\r\n    padding: 2rem;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\r\n    width: 100%;\r\n    max-width: 400px;\r\n    text-align: center;\r\n    border: 1px solid #f0f0f0;\r\n  }\r\n  \r\n  .logo-container {\r\n    text-align: center;\r\n    margin-bottom: 1.5rem;\r\n  }\r\n  \r\n  .company-logo {\r\n    width: 120px;\r\n    height: auto;\r\n  }\r\n  \r\n  .title {\r\n    margin-bottom: 1rem;\r\n    color: #333333;\r\n  }\r\n  \r\n  .description {\r\n    color: #555555;\r\n    margin-bottom: 1.5rem;\r\n  }\r\n  \r\n  .form {\r\n    text-align: left;\r\n  }\r\n  \r\n  .submit-button {\r\n    width: 100%;\r\n    background: #1890ff;\r\n    border: none;\r\n    color: #ffffff;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .submit-button:hover {\r\n    background: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
