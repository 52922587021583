import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.jsx';
import { MailOutlined, PhoneOutlined, HomeOutlined } from '@ant-design/icons';
import logo from '../img/Logo.png';

const ContactUs = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('phone', values.phone);
      formData.append('query', values.message);

      // Simulate fake submission success
      setTimeout(() => {
        setSubmitted(true);
        message.success('Form submitted successfully');
      }, 1000);
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Failed to submit the form. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="navbar">
        <Navbar />
      </div>
      <hr />
      <div className="contact-us">
        <h1>Contact Us</h1>
        {submitted ? (
          <div className="success-message">
            <p>Thank you for contacting us! We'll get back to you soon.</p>
          </div>
        ) : (
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="form-row">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter your name' }]}
                style={{ width: '48%' }}
              >
                <Input style={{ height: '50px', fontSize: '16px' }} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please enter your email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
                style={{ width: '48%' }}
              >
                <Input style={{ height: '50px', fontSize: '16px' }} />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: true, message: 'Please enter your phone number' }]}
                style={{ width: '100%' }}
              >
                <Input style={{ height: '50px', fontSize: '16px' }} />
              </Form.Item>
            </div>
            <Form.Item
              name="message"
              label="Message"
              rules={[{ required: true, message: 'Please enter your message' }]}
            >
              <Input.TextArea style={{ fontSize: '16px' }} rows={6} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={submitting}
                className="submit-button"
                style={{
                  width: '100%',
                  padding: 0,
                  border: 'none',
                  borderRadius: 0,
                  backgroundColor: 'black',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '18px',
                  transition: 'background-color 0.3s ease',
                }}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Form.Item>
          </Form>
        )}

        <div className="get-in-touch">
          <h2>Get in touch</h2>
          <div className="contact-info">
            <div className="contact-item">
              <HomeOutlined style={{ fontSize: '40px' }} />
              <span>Plot No. 18, Village Bhattiya, Near Jalandhar Bye-Pass, Ludhiana, Punjab (141008)</span>
            </div>
            <div className="contact-item">
              <MailOutlined style={{ fontSize: '40px' }} />
              <span>Info.karenpeach@gmail.com</span>
            </div>
            <div className="contact-item">
              <PhoneOutlined style={{ fontSize: '40px' }} />
              <span>+91 70098 86184</span>
            </div>
          </div>
        </div>

        <style jsx>{`
          .contact-us {
            width: 75%;
            margin: 0 auto;
            padding: 40px 0;
          }

          .contact-us h1 {
            text-align: center;
            margin-bottom: 30px;
          }

          .form-row {
            display: flex;
            justify-content: space-between;
            gap: 20px;
          }

          @media (max-width: 768px) {
            .form-row {
              flex-direction: column;
              width: 100%;
            }

            .contact-us {
              width: 90%;
            }
          }

          .success-message {
            background-color: #dff0d8;
            color: #3c763d;
            border: 1px solid #d6e9c6;
            border-radius: 5px;
            padding: 15px;
            margin-top: 20px;
            text-align: center;
          }

          .get-in-touch {
            width: 100%;
            padding: 60px 0;
            text-align: center;
          }

          .get-in-touch h2 {
            margin-bottom: 30px;
          }

          .contact-info {
            display: flex;
            justify-content: space-around;
            font-size: 18px;
            text-align: center;
          }

          @media (max-width: 768px) {
            .contact-info {
              flex-direction: column;
              align-items: center;
            }

            .contact-item {
              margin-bottom: 20px;
            }
          }

          .contact-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            max-width: 300px;
            margin: 0 20px;
          }

          .contact-item span {
            margin-top: 10px;
          }

          .submit-button {
            height: 50px;
            font-size: 18px;
          }
        `}</style>
      </div>
    </>
  );
};

export default ContactUs;
