import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const { authToken, isLoggedIn } = useAuth();
  const [stockStatus, setStockStatus] = useState({});
  const [wasFetchedWhileLoggedIn, setWasFetchedWhileLoggedIn] = useState(false);
  
  // New state variables for coupon
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAmount, setCouponAmount] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [CouponMessage, SetCouponMessage] = useState('');

  const findCartItemIndex = (id, color, size) => {
    return cart.findIndex(
      (item) => item.id === id && item.color === color && item.size === size
    );
  };

  const checkStock = useCallback(async (id, color, size, quantity, productId) => {
    try {
      const formData = new FormData();
      
      // Use `productId` if `authToken` is present, otherwise use `id`
      const idToUse = authToken ? productId : id;
  
      formData.append('productId', idToUse);
      formData.append('color', color);
      formData.append('size', size);
      formData.append('quantity', quantity);
  
      console.log(idToUse);
      console.log(productId);
      console.log(id);
      console.log(color);
      console.log(size);
      console.log(quantity);
  
      const response = await axios.post(
        'https://sharmasoftwaresolutions.com/karen_peach/index.php/user-check-product-in-stock',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      const { message, status } = response.data;
  
      const isProductInStock = status === '1' && message === 'Product is enough in stock';
  
      setStockStatus(prevStatus => ({
        ...prevStatus,
        [`${idToUse}-${size}-${color}`]: isProductInStock,
      }));
  
      return isProductInStock;
  
    } catch (error) {
      console.error('Error checking stock:', error);
      return false;
    }
  }, []); // Add `authToken` as a dependency if it's used in the function
  const checkStockk = useCallback(async (id, color, size, quantity) => {
    try {
      const formData = new FormData();
      
      // Use `productId` if `authToken` is present, otherwise use `id`
  
      formData.append('productId', id);
      formData.append('color', color);
      formData.append('size', size);
      formData.append('quantity', quantity);
  
      console.log(id);
      console.log(color);
      console.log(size);
      console.log(quantity);
  
      const response = await axios.post(
        'https://sharmasoftwaresolutions.com/karen_peach/index.php/user-check-product-in-stock',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      const { message, status } = response.data;
  
      const isProductInStock = status === '1' && message === 'Product is enough in stock';
  
      setStockStatus(prevStatus => ({
        ...prevStatus,
        [`${id}-${size}-${color}`]: isProductInStock,
      }));
  
      return isProductInStock;
  
    } catch (error) {
      console.error('Error checking stock:', error);
      return false;
    }
  }, []); // Add `authToken` as a dependency if it's used in the function
  

  const addToCart = async (productDetailsToAdd, selectedColor) => {
    const { id, size, quantity } = productDetailsToAdd;
    const color = selectedColor;

    try {
      const existingIndex = findCartItemIndex(id, color, size);

      let updatedCart;
      if (existingIndex !== -1) {
        updatedCart = [...cart];
        updatedCart[existingIndex].quantity += quantity;
      } else {
        updatedCart = [...cart, { ...productDetailsToAdd, quantity, color }];
      }

      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Save to local storage

      const isInStock = await checkStockk(id, color, size, quantity);
      if (!isInStock) {
        console.log('Item is out of stock');
        return;
      }

      if (authToken) {
        const formData = new FormData();
        formData.append('productId', id);
        console.log(id);
        
        formData.append('color', color);
        formData.append('size', size);
        formData.append('quantity', updatedCart[existingIndex]?.quantity || quantity);

        const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-add-product-to-cart', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'auth-token': authToken,
          },
        });

        console.log(response.data);
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const removeFromCart = async (id, size, color,productId) => {
    try {
      // Update the cart by filtering out the removed item
      const updatedCart = cart.filter(
        (item) => !(item.id === id && item.color === color && item.size === size)
      );
      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Save the updated cart to local storage
  
      // Send the request to remove the item from the server-side cart, if the user is logged in
      if (authToken) {
        const formData = new FormData();
        formData.append('productId', productId);
        console.log(id);
        
        formData.append('color', color);
        formData.append('size', size);
  
        const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-remove-product-from-cart', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'auth-token': authToken,
          },
        });
        await fetchCart();
        console.log('Response from remove-from-cart API:', response.data);
      }
    } catch (error) {
      console.error('Error removing from cart:', error);
    }
  };
  

  const updateQuantity = async (id, size, color, newQuantity,productId) => {
    try {
      const isInStock = await checkStock(id, color, size, newQuantity,productId);
      if (!isInStock) {
        console.log('Item is out of stock');
        return;
      }

      const updatedCart = cart.map((item) =>
        item.id === id && item.color === color && item.size === size
          ? { ...item, quantity: newQuantity }
          : item
      );

      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Save to local storage

      if (authToken) {
        const formData = new FormData();
        formData.append('productId', productId);
        console.log(id);
        
        formData.append('color', color);
        formData.append('size', size);

        const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-add-product-to-cart', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'auth-token': authToken,
          },
        });

        console.log(response.data);
        await fetchCart();
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const fetchCart = useCallback(async () => {
    if (isLoggedIn && authToken) {
      try {
        const response = await axios.get('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-cart', {
          headers: {
            'auth-token': authToken,
          },
        });

        if (response.data.status === '1') {
          setCart(response.data.details);
          localStorage.setItem('cart', JSON.stringify(response.data.details));
          setWasFetchedWhileLoggedIn(true);
        } else {
          console.error('Error fetching cart:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching cart:', error);
      }
    }
  }, [authToken, isLoggedIn]);

  useEffect(() => {
    const storedCart = localStorage.getItem('cart');

    const loadCart = async () => {
      if (isLoggedIn && authToken) {
        await fetchCart();
      } else if (storedCart) {
        setCart(JSON.parse(storedCart));
      }
    };

    loadCart();
  }, [isLoggedIn, authToken, fetchCart]);

  useEffect(() => {
    if (!isLoggedIn && wasFetchedWhileLoggedIn) {
      setCart([]);
      localStorage.removeItem('cart');
      setWasFetchedWhileLoggedIn(false);
    }
  }, [isLoggedIn, wasFetchedWhileLoggedIn]);

  // New function to apply coupon
  // const applyCoupon = async (couponCode, originalAmount, country, authToken) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('coupon', couponCode);
  //     formData.append('amount', originalAmount);
  //     formData.append('country', country);
  
  //     // Conditionally add 'auth-token' header only if authToken is available
  //     const headers = authToken ? { 'auth-token': authToken } : {};
  
  //     const response = await axios.post(
  //       'https://sharmasoftwaresolutions.com/karen_peach/index.php/user-check-coupon',
  //       formData,
  //       { headers }
  //     );
  
  //     if (response.data.status === '1') {
  //       console.log(response);
        
  //       setCouponAmount(response.data.details.new_amount);
  //       setCouponApplied(true);
  //       setCouponCode(couponCode);
  //     } else {
  //       console.error('Error applying coupon:', response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error applying coupon:', error);
  //   }
  // };
  const [reduceAmountBy99, setReduceAmountBy99] = useState(false);

  const applyCoupon = async (couponCode, originalAmount, country, authToken) => {
    try {
      const formData = new FormData();
      formData.append('coupon', couponCode);
      formData.append('amount', originalAmount);
      formData.append('country', country);
  
      const headers = authToken ? { 'auth-token': authToken } : {};
  
      const response = await axios.post(
        'https://sharmasoftwaresolutions.com/karen_peach/index.php/user-check-coupon',
        formData,
        { headers }
      );
  
      if (response.data.status === '1') {
        // Apply coupon if successful
        setCouponAmount(response.data.details.new_amount);
        setCouponApplied(true);
        setCouponCode(couponCode);
  
       
      
      } else if (response.data.status === '10') {
         // Set reduceAmountBy99 flag if status is exactly '10'
         
        setCouponAmount(originalAmount);
         setReduceAmountBy99(response.data.status === '10'); // Only true if status is 10
         console.log("Reduce Amount by 99:", response.data.status === '10');
        setCouponApplied(true);
      } 
      else {
        console.error('Error applying coupon:', response.data.message);
        SetCouponMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
    }
  };
  
   

  const removeCoupon = () => {
    setCouponApplied(false);
    setCouponAmount(0);
    setCouponCode('');
    window.location.reload(); // This will reload the page
  };

  const calculateTotalPrice = () => {
    // Calculate the total price by summing up the price of each item multiplied by its quantity
    const totalPrice = cart.reduce((total, item) => {
      const itemTotal = (item.price_inr || item.price) * item.quantity;
      return total + itemTotal;
    }, 0);
  
    // If a coupon is applied, return the discounted total
    return couponApplied ? couponAmount : totalPrice;
  };
  

  
  const values = {
    cart,
    addToCart,
    removeFromCart,
    CouponMessage,
    updateQuantity,
    stockStatus,
    couponApplied, // Add coupon states to context
    couponAmount,
    couponCode,
    calculateTotalPrice,
    applyCoupon,
    removeCoupon,
    reduceAmountBy99
  };

  return <CartContext.Provider value={values}>{children}</CartContext.Provider>;
};

export const useCart = () => {
  return useContext(CartContext);
};
