// CheckoutForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from './CartContext';

import logo from '../img/Logo.png'; // Import your logo
import '../css/checkout.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const CheckoutForm = () => {
  const { cart } = useCart();
  const [openAccordion, setOpenAccordion] = useState(null);
  const [formData, setFormData] = useState({
    billingEmail: '',
    repeatBillingEmail: '',
    title: '',
    firstName: '',
    lastName: '',
    country: '',
    pincode: '',
    city: '',
    street: '',
    number: '',
    additional: '',
    telephone: '',
    day: '',
    month: '',
    year: '',
    newsletter: false,
    userMessage: '',
  });

  const navigate = useNavigate();

  // Load form data from sessionStorage if it exists
  useEffect(() => {
    const storedData = sessionStorage.getItem('checkoutData');
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  const handleChange = (e) => {
    const { id, value, type, checked, name } = e.target;
    const key = id || name; // Use 'name' if 'id' is not present
    setFormData((prevData) => ({
      ...prevData,
      [key]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = () => {
    // Store form data and navigate to payment
    sessionStorage.setItem('checkoutData', JSON.stringify(formData));
    navigate('/payment');
  };

const calculateTotalPrice = () => {
  return cart.reduce((total, item) => {
    const itemPrice = item.price !== undefined ? item.price : item.price_inr;
    return total + itemPrice * item.quantity;
  }, 0) + 99; // Adds 99 to the final total
};


  const toggleAccordionItem = (item) => {
    setOpenAccordion((prevItem) => (prevItem === item ? null : item));
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        {/* Left side - Logo */}
        <div className="logo-container">
          <Link to="/">
            <img src={logo} height="50px" alt="Logo" />
          </Link>
        </div>

        {/* Right side - Secure Shopping */}
        <div className="secure-shopping-container text-right d-flex align-items-center">
          <div className="hotline">
            <div
              className="hotline-number"
              style={{ fontSize: '14px', marginRight: '15vw' }}
            >
              Free hotline:
            </div>
            <div className="d-flex">
              <div
                className="hotline-number"
                style={{ fontSize: '11px', marginRight: '10px' }}
              >
                +49 8062 72133-10
              </div>
              <div
                className="hotline-timing"
                style={{ fontSize: '11px', marginRight: '20px' }}
              >
                Mo. to Fr., 9 am - 5 pm
              </div>
            </div>
          </div>
          <div className="secure-icon">
            {/* You can use an actual secure icon here */}
            <span role="img" aria-label="Secure Icon">
              🔒
            </span>
          </div>
          <div className="secure-text">
            <p style={{ marginTop: '18px' }}>Secure Shopping</p>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5" style={{ padding: '29px' }}>
        <div className="row px-5">
          <div className="col-md-1 ">
            <span>1.Basket</span>
          </div>
          <div className="col-md-4 offset-md-7">
            {`Rs.${calculateTotalPrice()}`} incl. VAT{' '}
            <Link to="/cart" style={{ color: 'black', textDecoration: 'underline' }}>
              Change
            </Link>
          </div>
        </div>
        <hr style={{ border: '1px solid black' }} />
        <div className="row mt-4 px-5">
          <div className="col-md-5">
            <div className="d-flex">
              <h5 className="fs-3">2. My Details</h5>
              <h6 className="mt-5">Billing Address</h6>
            </div>
            <hr style={{ border: '1px solid black' }} />
            <label htmlFor="billingEmail">Your E-mail Address</label>
            <input
              type="email"
              className="form-control"
              id="billingEmail"
              value={formData.billingEmail}
              onChange={handleChange}
            />

            <label htmlFor="repeatBillingEmail">Repeat Email Address</label>
            <input
              type="email"
              className="form-control"
              id="repeatBillingEmail"
              value={formData.repeatBillingEmail}
              onChange={handleChange}
            />

            <label>Title</label>
            <div className="btn-group w-100">
              <label className="btn btn-outline-dark mr-1 form-check-label">
                <input
                  type="radio"
                  name="title"
                  value="Mr."
                  className="form-check-input"
                  checked={formData.title === 'Mr.'}
                  onChange={handleChange}
                />{' '}
                Mr.
              </label>
              <label className="btn btn-outline-dark ml-1 form-check-label">
                <input
                  type="radio"
                  name="title"
                  value="Ms."
                  className="form-check-input"
                  checked={formData.title === 'Ms.'}
                  onChange={handleChange}
                />{' '}
                Ms.
              </label>
            </div>

            <hr className="custom-hr" />

            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="country">Country</label>
              <select
                className="form-control"
                id="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                <option value="Country 1">Country 1</option>
                <option value="Country 2">Country 2</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="pincode">PinCode</label>
              <input
                type="text"
                className="form-control"
                id="pincode"
                value={formData.pincode}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>

            <div className="form-row">
              <div className="form-group col-md-7">
                <label htmlFor="street">Street</label>
                <input
                  type="text"
                  className="form-control"
                  id="street"
                  value={formData.street}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="number">Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  value={formData.number}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="additional">Address details</label>
              <input
                type="text"
                className="form-control"
                id="additional"
                value={formData.additional}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="telephone">Telephone no. - optional</label>
              <input
                type="text"
                className="form-control"
                id="telephone"
                value={formData.telephone}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>
                <strong>Date of Birth </strong> - we'd love to surprise you. Let us know when
                your birthday is and subscribe to our newsletter.
              </label>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    id="day"
                    placeholder="DD"
                    value={formData.day}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    id="month"
                    placeholder="MM"
                    value={formData.month}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    id="year"
                    placeholder="YYYY"
                    value={formData.year}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="newsletter"
                checked={formData.newsletter}
                onChange={handleChange}
                style={{
                  marginBottom: 'initial',
                  padding: 'initial',
                  width: 'initial',
                  borderRadius: 'initial',
                  border: 'initial',
                  fontSize: 'initial',
                  color: 'initial',
                }}
              />
              <label className="form-check-label" htmlFor="newsletter">
                Yes, I would like to request the free Karen Peach newsletter, and I authorize
                the receipt of regular personalised product recommendations by e-mail. You can
                unsubscribe at any time. Information about personalization and the use of my
                data can be found in the detailed newsletter consent information and the data
                protection information.
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="userMessage">Your message to us - optional</label>
              <textarea
                className="form-control"
                id="userMessage"
                rows="5"
                placeholder="Personal notes can delay the ordering process."
                value={formData.userMessage}
                onChange={handleChange}
              ></textarea>
            </div>

            <button className="btn btn-warning btn-block mt-3" onClick={handleSubmit}>
              Continue
            </button>
          </div>

          <div className="col-md-4" style={{ maxWidth: '100%' }}>
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <h6 className="mt-5">YOUR PURCHASE</h6>
              <h6 className="mt-5 offset-md-6">Price</h6>
            </div>

            <hr style={{ border: '1px solid black' }} />

            <div className="d-flex flex-column">
              {/* Map through cart items and display them in the checkout form */}
              {cart.map((item) => (
                <div className="d-flex" key={item.productId}>
                  <div className="card mr-3" style={{ flex: '0 0 auto' }}>
                    <img
                      src={item.image}
                      className="card-img-top img-fluid"
                      style={{ height: '100px', width: '75px' }}
                      alt={`Product ${item.productId}`}
                    />
                  </div>
                  <div className="mr-3" style={{ flex: '1 1 auto' }}>
                    <strong>{item.name}</strong>
                    <div style={{ color: '#888' }}>Size: {item.size}</div>
                    <div style={{ color: '#888' }}>Quantity: {item.quantity}</div>
                  </div>
                  <div
                    className="pricee"
                    style={{ flex: '0 0 auto', marginLeft: '3rem' }}
                  >
                    <div>{`Rs.${
                      (item.price !== undefined ? item.price : item.price_inr) * item.quantity
                    }`}</div>
                  </div>
                </div>
              ))}

              <hr style={{ border: '1px solid black' }} />

              {/* Display shipping costs, included VAT, and total price */}
              <div className="d-flex">
                <div className="mr-2" style={{ color: '#888' }}>
                  Shipping costs
                </div>
                <div className="offset-md-7" style={{ color: '#888' }}>
                  Rs.99
                </div>
              </div>
              <hr style={{ border: '1px solid black' }} />
              <div className="d-flex">
                <div className="mr-2" style={{ color: '#888' }}>
                  Included VAT
                </div>
                <div className="offset-md-7" style={{ color: '#888' }}>
                  Rs. 00,00
                </div>
              </div>
              <hr style={{ border: '1px solid black' }} />
              <div className="d-flex">
                <div className="mr-4">Total Price</div>
                <div className="offset-md-7">{`Rs. ${calculateTotalPrice()}`}</div>
              </div>
              <hr style={{ border: '1px solid black' }} />
              <div style={{ color: '#888' }}>*Lowest price in the last 30 days</div>
            </div>

            <style jsx>{`
              @media (max-width: 375px) {
                .col-md-4 {
                  padding: 0 10px;
                }
                .d-flex {
                  flex-direction: column;
                  align-items: flex-start;
                }
                .pricee {
                  margin: 0 !important;
                }
                .mr-3,
                .ml-5,
                .mx-5 {
                  margin: 0 !important;
                }
                .offset-md-6,
                .offset-md-7 {
                  margin-left: 0;
                }
                .card-img-top {
                  height: 75px;
                  width: 50px;
                }
              }
              @media (min-width: 376px) and (max-width: 720px) {
                .pricee {
                  margin: 0 !important;
                }
              }
            `}</style>
          </div>

          <div className="col-md-3 mt-5">
            <hr style={{ border: '1px solid black', margin: 0 }} />
            <div className="accordion" id="accordionExample">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton onClick={() => toggleAccordionItem('hotline')}>
                      Hotline
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Telephone:
                      <br />
                      +49 8062 72133-10
                    </p>
                    <p>Mon. - Fri. 9 am to 5 pm</p>
                    <p>E-Mail: service@Karen Peach.com</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton onClick={() => toggleAccordionItem('shippingInfo')}>
                      Shipping Information
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Free shipping within the EU.</p>
                    <p>
                      Outside the EU, the delivery is free of charge for all purchases of more
                      than € 120.
                    </p>
                    <p>More information can be found here.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton onClick={() => toggleAccordionItem('freeReturn')}>
                      Free return
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      If you do not like the product you ordered, you may return it free of
                      charge without stating any reason within a period of 30 days. It will then
                      be shipped back to us climate-neutrally.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton onClick={() => toggleAccordionItem('secureShopping')}>
                      Secure shopping
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x"></i>
                    </div>
                    <p className="mt-3">
                      You are assured a high degree of security with the most advanced encryption
                      techniques available, such as Secure Socket Layer (SSL) and the 3D Secure
                      service.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton onClick={() => toggleAccordionItem('paymentMethods')}>
                      Our payment methods
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="row">
                      {/* Add your payment method images and content here */}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>

        <hr style={{ border: '1px solid black' }} />
        <div className="col-md-5 px-5">
          <Link to="/payment" style={{ color: 'black' }}>
            3. Payment
          </Link>
        </div>
      </div>
      <footer className="container-fluid py-3 mt-5">
        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-md-2">
            <h5 className="text-center" style={{ color: 'white' }}>
              More Inspiration
            </h5>
            <div className="footer-icons">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>

          <div className="col-md-2"></div>

          <div className="col-md-7">
            <div className="footer-links">
              <a href="#">Legal Notice</a>
              <a href="#">General terms and condition</a>
              <a href="#">Privacy Policy</a>
              <a href="#">Usage Terms</a>
              <a href="#">Cancellation</a>
            </div>
            <div className="footer-line">
              <p>&copy; Karen Peach</p>
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>
      </footer>
    </>
  );
};

export default CheckoutForm;
