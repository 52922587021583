import React, { useState } from 'react';
import Navbar from './Navbar.jsx';
import { useCart } from './CartContext';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-router-dom';

export default function Cart() {
  const isLargeScreen = useMediaQuery({ minWidth: 768 });
  const {
    cart,
    stockStatus,
    removeFromCart,
    updateQuantity,
    couponApplied,
    couponAmount,
    couponCode,
    applyCoupon,
    removeCoupon,
    calculateTotalPrice,
  } = useCart();

  const navigate = useNavigate();
  const [couponCodeInput, setCouponCodeInput] = useState('');
  const [applyingCoupon, setApplyingCoupon] = useState(false);

  const handleCouponCodeChange = (event) => {
    setCouponCodeInput(event.target.value);
  };

  const handleApplyCoupon = async () => {
    setApplyingCoupon(true);
    const originalAmount = calculateTotalPrice();
    await applyCoupon(couponCodeInput, originalAmount, 'IND');
    setApplyingCoupon(false);
  };

  const handleRemoveCoupon = () => {
    removeCoupon();
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5">
        <Card className="add-to-cart"
          style={{
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          <div className="row">
            <div className="col-md-8 cart">
              <CardContent>
                <div className="title">
                  <div className="row">
                    <div className="col">
                      <h1 style={{ color: 'black' }}><b>Shopping Cart</b></h1>
                    </div>
                    <div className="col align-self-center text-right text-muted" style={{ color: 'black' }}>
                      {cart.length} items
                    </div>
                  </div>
                </div>

                {cart.map((item) => (
                  <div className={`row border-top border-bottom ${
                      stockStatus[`${item.id}-${item.size}-${item.color}`] === false ? 'out-of-stock' : ''
                    }`}
                    key={item.productId}
                    style={{
                      width: !isLargeScreen && '98%',
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      marginBottom: '15px',
                      padding: '15px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    <div className="row main align-items-center">
                      <div className="col-2">
                        <img className="img-fluid" src={item.image} alt={`Product ${item.productId}`}
                          style={{
                            borderRadius: '8px',
                            height: !isLargeScreen && '50px',
                            width: !isLargeScreen && '40px !important',
                            maxWidth: !isLargeScreen && 'none',
                          }}
                        />
                      </div>
                      <div className="col">
                        <div className="row text-muted">{item.category}</div>
                        <div className="row" style={{
                            fontSize: isLargeScreen ? '18px' : '14px',
                            fontWeight: 'bold',
                            marginLeft: !isLargeScreen && '18px',
                          }}
                        >
                          {item.name}
                        </div>
                        <div style={{ color: 'black', marginLeft: !isLargeScreen && '18px' }}>
                          {item.size}
                        </div>
                      </div>
                      <div className="col">
                        <IconButton onClick={() => removeFromCart(item.id, item.size, item.color,item.productId)} disabled={couponApplied}>
                          <DeleteIcon style={{ color: 'rgb(209, 38, 63)', marginLeft: isLargeScreen ? 'none' : '36px' }} />
                        </IconButton>
                      </div>
                      <div className="col" style={{ marginTop: !isLargeScreen && '18px' }}>
                        <TextField type="number" variant="outlined" size="small" value={item.quantity}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton onClick={() => removeFromCart(item.id, item.size, item.color,item.productId)}>
                                  <RemoveIcon style={{ color: 'rgb(209, 38, 63)' }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => updateQuantity(item.id, item.size, item.color, item.quantity + 1,item.productId )}>
                                  <AddIcon style={{ color: 'rgb(209, 38, 63)' }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            style: {
                              fontSize: '16px',
                              color: 'black',
                              textAlign: 'center',
                            },
                            inputProps: {
                              style: {
                                textAlign: 'center',
                                width: isLargeScreen ? '50px' : '25px',
                              },
                            },
                          }}
                        />
                      </div>
                      <div className="col" style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: isLargeScreen ? 'none' : '30px' }}>
  <span style={{ textDecoration: 'line-through', color: 'gray' }}>
    &#8377; {item.price_inr * item.quantity || item.price * item.quantity}
  </span>
  <br />
  <span style={{ color: 'green' }}>
    &#8377; {(item.price_inr * item.quantity || item.price * item.quantity) / 2}
  </span>
</div>
                      {stockStatus[`${item.id}-${item.size}-${item.color}`] === false && (
                        <div className="out-of-stock-message" style={{ color: 'rgb(209, 38, 63)', fontSize: '16px' }}>
                          Out of Stock
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <div className="back-to-shop">
                  <Link to="/" style={{ textDecoration: 'none', color: 'rgb(209, 38, 63)', fontSize: '18px' }}>
                    <ArrowBackIcon style={{ marginRight: '5px', fontSize: '20px' }} />
                    Back to shop
                  </Link>
                </div>
              </CardContent>
            </div>

            <div className="col-md-4 summary">
              <CardContent style={{
                backgroundColor: 'white',
                borderRadius: '12px',
                padding: '20px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}>
                <Typography variant="h5" style={{ fontWeight: 'bold', color: 'black', marginBottom: '20px' }}>
                  Order Summary
                </Typography>

                <div style={{
                  borderRadius: '10px',
                  backgroundColor: 'white',  // Baby pink background
                  padding: '15px',
                  marginBottom: '20px',
                  color: 'black',
                }}>
                  <div>
  <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '10px', marginRight: '9px' }}>
    Total Price: 
    <span style={{ textDecoration: 'line-through', color: 'gray', marginRight: '9px', marginLeft: '9px' }}>
      &#8377; {calculateTotalPrice()}
    </span>
    <span style={{ color: 'green' }}>
      &#8377; {calculateTotalPrice() / 2}
    </span>
  </Typography>
</div>

                  {/* {couponApplied && (
                    <Typography variant="body1" style={{ color: 'green', marginBottom: '10px' }}>
                      Coupon "{couponCode}" applied! New Price: &#8377; {couponAmount}
                    </Typography>
                  )} */}
                </div>

                {/* {!couponApplied && (
                  <div style={{ marginBottom: '20px' }}>
                    <TextField label="Coupon Code" value={couponCodeInput} onChange={handleCouponCodeChange}
                      variant="outlined" fullWidth size="small" style={{
                        marginBottom: '10px',
                        backgroundColor: 'white',
                      }}
                      disabled={applyingCoupon}
                    />
                    <Button variant="contained" fullWidth
                      style={{
                        backgroundColor: 'rgb(209, 38, 63)',  // Baby pink button
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      onClick={handleApplyCoupon} disabled={applyingCoupon || !couponCodeInput.trim()}>
                      {applyingCoupon ? 'Applying...' : 'Apply Coupon'}
                    </Button>
                  </div>
                )}

                {couponApplied && (
                  <Button variant="outlined" fullWidth
                    style={{
                      borderColor: 'rgb(209, 38, 63)',
                      fontWeight: 'bold',
                      marginBottom: '20px',
                    }}
                    onClick={handleRemoveCoupon}>
                    Remove Coupon
                  </Button>
                )} */}

<Button
  variant="contained"
  color="secondary"
  fullWidth
  style={{
    backgroundColor: cart.length === 0 ? '#ccc' : 'rgb(209, 38, 63)', // Gray if disabled
    color: cart.length === 0 ? '#666' : 'black',
    fontWeight: 'bold',
    marginTop: '20px',
  }}
  disabled={cart.length === 0} // Disable the button if cart is empty
  onClick={() => navigate('/checkout')}
>
  Proceed to Checkout
</Button>

              </CardContent>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
