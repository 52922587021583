import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Link } from 'react-router-dom';
import { useCart } from './CartContext';
import logo from '../img/Logo.png';
import { useAuth } from "./AuthContext";
import visa from './visa (1).png';
import visa1 from './visa (2).png';
import visa2 from './visa (3).png';
import visa3 from './visa (4).png';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';

const Payment = () => {
  
  const { authToken } = useAuth();
  const [selectedPayment, setSelectedPayment] = useState('Razorpay');
  const [orderId, setOrderId] = useState(null);
  const { cart,
    stockStatus,
    removeFromCart,
    updateQuantity,
    couponApplied,
    couponAmount,
    couponCode,
    applyCoupon,
    CouponMessage,
    removeCoupon,
    reduceAmountBy99,
    calculateTotalPrice
   } = useCart(); // cart from context API
  const navigate = useNavigate();
  console.log(reduceAmountBy99);
  
  
  const [couponCodeInput, setCouponCodeInput] = useState('');
  const [applyingCoupon, setApplyingCoupon] = useState(false);

  const handleCouponCodeChange = (event) => {
    setCouponCodeInput(event.target.value);
  };

  const handleApplyCoupon = async () => {
    setApplyingCoupon(true);
    const originalAmount = calculateTotalPrice();
    await applyCoupon(couponCodeInput, originalAmount, 'IND', authToken ? authToken : undefined);
    setApplyingCoupon(false);
  };
  

  const handleRemoveCoupon = () => {
    removeCoupon();
  };

  // Logging the cart to debug

  useEffect(() => {
    const formData = sessionStorage.getItem('checkoutData');
    console.log(formData);
    
    if (!formData) {
      navigate('/checkout');
    }

    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [navigate]);




  const generateOrderId = async (amount) => {
    try {
      const formData = new FormData();
      formData.append('amount', amount);
  
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-generate-orderId', {
        method: 'POST',
        body: formData,
      });
  
      // Log the raw response object
      console.log('Raw response:', response);
  
      // Parse and log the response JSON
      const data = await response.json();
      console.log('Parsed response data:', data);
  
      if (data.status === '1') {
        setOrderId(data.details.id);
        return data.details.id;
      } else {
        console.error('Failed to generate order ID:', data.message);
      }
    } catch (error) {
      console.error('Error generating order ID:', error);
    }
    return null;
  };
  
  
  const discountRate =  0.5;
  const handleRazorpayPayment = async () => {
// Calculate total amount and round to 2 decimal places
const totalAmount = reduceAmountBy99 
  ? parseFloat((calculateTotalPrice() * discountRate * 100).toFixed(2))
  : parseFloat((calculateTotalPrice() * discountRate * 100 +9900).toFixed(2));
    const order_id = await generateOrderId(totalAmount);

    if (!order_id) {
      console.error('Order ID not generated. Aborting payment.');
      return;
    }

    const options = {
      // key: 'rzp_test_wUNBH0QF966huh',
      keyId : 'rzp_live_KwGXf9PqRn2iXL', // Public Key
      secretKey : 'kmwGcs6ttNZDdoOXxKdXj0ES',
      amount: totalAmount,
      currency: 'INR',
      name: 'Karen Peach',
      description: 'Karen Peach',
      image: logo,
      order_id: order_id,
      handler: async function (response) {
        console.log(response);
        await handleOrderSubmission(response);
      },
      // prefill: {
      //   name: 'John Doe',
      //   email: 'john.doe@example.com',
      //   contact: '9999999999',
      // },
      theme: {
        color: '#3399cc',
      },
      modal: {
        ondismiss: function () {
          console.log('Transaction was not completed.');
        }
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  console.log(cart);

  const transformFormData = (formData, cart) => {
    const address = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      country: formData.country,
      post_code: formData.pincode,
      city: formData.city,
      street: formData.street,
      number: formData.number,
      email:formData.billingEmail,
      address: formData.additional,
      phone: formData.telephone,
    };

    // Ensure cart is an array to avoid errors
    let requestBody;

    if (authToken) {
      requestBody = {
        billing_address: { ...address },
        shipping_address: { ...address },
        cart: cart?.map(item => ({
          productId: item.productId, // Use productId when authToken is present
          color: item.color,
          size: item.size,
          quantity: item.quantity.toString(),
        })) || [], // Fallback to empty array if cart is not available
        country: 'IND', // This can be dynamic if needed.
        signature: 'signature_here', // You'll need to dynamically generate this if required.
        payment_id: 'payment_id_here', // Replace with actual payment ID from Razorpay response.
        order_id: 'razorpay_order_id', // Replace with actual order ID from Razorpay response.
        ...(couponApplied ? { coupon: couponCodeInput } : {}),
      };
    } else {
      requestBody = {
        billing_address: { ...address },
        shipping_address: { ...address },
        cart: cart?.map(item => ({
          productId: item.id, // Use id when authToken is not present
          color: item.color,
          size: item.size,
          quantity: item.quantity.toString(),
        })) || [], // Fallback to empty array if cart is not available
        country: 'IND', // This can be dynamic if needed.
        signature: 'signature_here', // You'll need to dynamically generate this if required.
        payment_id: 'payment_id_here', // Replace with actual payment ID from Razorpay response.
        order_id: 'razorpay_order_id', // Replace with actual order ID from Razorpay response.
        ...(couponApplied ? { coupon: couponCodeInput } : {}), 
      };
    }
    

    // Conditionally add the coupon field if it's available
    if (formData.coupon) {
      requestBody.coupon = formData.coupon;
    }

    return requestBody;
  };
  console.log(transformFormData);
  

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const handleOrderSubmission = async (paymentResponse) => {
    const formData = JSON.parse(sessionStorage.getItem('checkoutData'));
  
    if (!cart) {
      console.error('Cart is null or undefined');
      return;
    }
  
    const requestBody = transformFormData(formData, cart);
    requestBody.signature = paymentResponse.razorpay_signature;
    requestBody.payment_id = paymentResponse.razorpay_payment_id;
    requestBody.order_id = paymentResponse.razorpay_order_id;
  
    setIsLoading(true); // Start loading
  
    try {
      // Prepare headers object
      const headers = {
        'Content-Type': 'application/json',
      };
  
      if (authToken) {
        headers['auth-token'] = authToken;
      } else {
        headers['auth-token'] = ''; // Optional
      }
  
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-make-order', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody),
      });
  
      // Check if response is JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (data.status === '1') {
          console.log('Order submitted successfully:', data.message);
          navigate('/order-confirmation');
        } else {
          console.error('Failed to submit order:', data.message);
        }
      } else {
        const text = await response.text();
        console.error('Unexpected response format:', text);
      }
    } catch (error) {
      console.error('Error submitting order:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handlePaymentSelection = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const handleContinueClick = () => {
    if (selectedPayment === 'Razorpay') {
      handleRazorpayPayment();
    }
  };

  return (
    <>
        {isLoading ? (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Processing your Order...</p>
      </div>
    ) : (
      <div>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        {/* Left side - Logo */}
        <div className="logo-container">
          <a href="./">
            <img src={logo} height="50px" alt="Logo" />
          </a>
        </div>

        {/* Center - Free Hotline */}

        {/* Right side - Secure Shopping */}
        <div className="secure-shopping-container text-right d-flex align-items-center">
          <div className="hotline">
            <div className="hotline-number" style={{ fontSize: "14px", marginRight: "15vw" }}>Free hotline:</div>
            <div className="d-flex">
              <div className="hotline-number" style={{ fontSize: "11px", marginRight: "10px" }}>+49 8062 72133-10</div>
              <div className="hotline-timing" style={{ fontSize: "11px", marginRight: "20px" }}>Mo. to Fr., 9 am - 5 pm</div>
            </div>
          </div>
          <div className="secure-icon">
            <span role="img" aria-label="Secure Icon">🔒</span>
          </div>
          <div className="secure-text">
            <p style={{ marginTop: "18px" }}>Secure Shopping</p>
          </div>
        </div>
      </div>

      <hr />
      <div className="container-fluid mt-5" style={{ padding: "19px" }}>

        <div className="row px-5" style={{ height: '40px' }}>
          <div className="col-md-1 ">
            <span>1.Basket</span>
          </div>
          <div className="col-md-4 offset-md-7">
            {`Rs. ${calculateTotalPrice()} incl. VAT`}  <Link to="/cart" style={{ color: 'black', textDecoration: 'underline' }}>Change</Link>
          </div>
        </div>
        <hr style={{ border: '1px solid black' }} />
        <div className="row px-5" style={{ height: '48px', padding: "12px" }}>
          <div className="col-md-1 ">
            <span>2.Billing</span>
          </div>
          <div className="col-md-4 offset-md-7">
            <Link to="/checkout" style={{ color: 'black', textDecoration: 'underline' }}>Change Address</Link>
          </div>
        </div>
        <hr style={{ border: '1px solid black' }} />

        <div className="row mt-4 px-5">
          <div className="col-md-5">
            <div className="d-flex align-items-center mb-4">
              <h5 className="fs-3" style={{ fontWeight: 'bold', color: '#333' }}>Payment</h5>
            </div>

            <div className="mt-3">
              <div className="payment-method p-3" style={{ border: '1px solid #ddd', display: 'flex', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
                <input
                  type="radio"
                  id="razorpay"
                  className='input1'
                  name="paymentMethod"
                  value="Razorpay"
                  checked={selectedPayment === 'Razorpay'}
                  onChange={() => handlePaymentSelection('Razorpay')}
                  style={{ marginRight: '2px' }}
                />
                <label htmlFor="razorpay" style={{ fontSize: '16px', color: '#555' }}>Razorpay Secure (UPI, Cards, Wallets, NetBanking)</label>
                <div className="icons mt-2 d-flex align-items-center" style={{ gap: '10px', transition: 'all 0.3s ease-in-out' }}>
                  <img src={visa} alt="UPI" style={{ width: '40px', height: '25px' }} />
                  <img src={visa1} alt="Visa" style={{ width: '40px', height: '25px' }} />
                  <img src={visa2} alt="MasterCard" style={{ width: '40px', height: '25px' }} />
                  <img src={visa3} alt="RuPay" style={{ width: '40px', height: '25px' }} />
                  <span style={{ fontSize: '14px', color: '#888' }}>+16</span>
                </div>
              </div>
            </div>

            {selectedPayment === 'Razorpay' && (
              <div className="mt-4 text-center">
                <button
                  className="btn btn-primary"
                  style={{
                    color: 'white',
                    background: 'linear-gradient(90deg, #000000, #333333)',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    transition: 'background 0.3s ease',
                  }}
                  onClick={handleContinueClick}
                  onMouseOver={(e) => e.currentTarget.style.background = 'linear-gradient(90deg, #333333, #000000)'}
                  onMouseOut={(e) => e.currentTarget.style.background = 'linear-gradient(90deg, #000000, #333333)'}
                >
                  Pay Now
                </button>
              </div>
            )}
          </div>

          <div className="col-md-4">
      <div className="d-flex">
        <h6 className="mt-5">YOUR PURCHASE</h6>
        <h6 className="mt-5 offset-md-6">Price</h6>
      </div>

      <hr style={{ border: '1px solid black' }} />

      <div className="d-flex flex-column">
        {cart.map((item) => (
          <div className="d-flex" key={item.productId}>
            <div className="card mr-3">
              <img
                src={item.image}
                className="card-img-top img-fluid"
                style={{ height: '100px', width: '75px' }}
                alt={`Product ${item.productId}`}
              />
            </div>
            <div className="mr-3">
              <strong>{item.name}</strong>
              <div style={{ color: '#888' }}>Size: {item.size}</div>
              <div style={{ color: '#888' }}>Quantity: {item.quantity}</div>
            </div>
            <div className="ml-5">
              <div>{`Rs.${(item.price_inr !== undefined
                ? item.price_inr
                : item.price) * item.quantity}`}</div>
            </div>
          </div>
        ))}

        <hr style={{ border: '1px solid black' }} />
        <div className="d-flex">
          <div className="mr-2" style={{ color: '#888' }}>
             Amount 
          </div>
          <div className="offset-md-7" style={{ color: '#888' }}>
          <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '10px' }}>
    Rs.{calculateTotalPrice().toFixed(2)}
  </span>
  </div>
  

        </div>
        
        <div style={{ color: 'green', fontWeight: 'bold' }}>
  Flat 50% Additional Off!
</div>




        <hr style={{ border: '1px solid black' }} />
        <div className="d-flex">
          <div className="mr-3" style={{ color: '#888' }}>
             Discounted Price 
          </div>
  
  <div className="offset-md-7" style={{ color: '#888' }}>

  {/* Discounted Price */}
  <span style={{color:'black'}}>
    Rs.{(calculateTotalPrice() * discountRate).toFixed(2)}
  </span>
          </div>
          </div>
        <hr style={{ border: '1px solid black' }} />

        <div className="d-flex">
          <div className="mr-2" style={{ color: '#888' }}>
            Shipping costs
          </div>
          <div className="offset-md-7" style={{ color: '#888' }}>
            {reduceAmountBy99?0:99}
          </div>
        </div>

        <hr style={{ border: '1px solid black' }} />
        <div className="d-flex">
        <div className="mr-4">Total Price</div>
        <div className="offset-md-7">
  {`Rs.${(reduceAmountBy99 ? calculateTotalPrice()* discountRate : calculateTotalPrice()* discountRate + 99).toFixed(2)}`}
</div>



        </div>
        <hr style={{ border: '1px solid black' }} />
        <div style={{ color: 'Black', fontWeight: 'bold' }}>
        </div>

        {!couponApplied && (
  <div style={{ marginBottom: '20px' }}>
    <TextField 
      label="Coupon Code" 
      value={couponCodeInput} 
      onChange={handleCouponCodeChange}
      variant="outlined" 
      fullWidth 
      size="small" 
      style={{
        marginBottom: '10px',
        backgroundColor: 'white',
      }}
      disabled={applyingCoupon}
    />
    <Button 
      variant="contained" 
      fullWidth
      style={{
        backgroundColor: 'rgb(209, 38, 63)',  // Baby pink button
        color: 'black',
        fontWeight: 'bold',
      }}
      onClick={handleApplyCoupon} 
      disabled={applyingCoupon || !couponCodeInput.trim()}
    >
      {applyingCoupon ? 'Applying...' : 'Apply Coupon'}
    </Button>
    
    {/* Display coupon message if available */}
    {CouponMessage && (
      <p style={{
        color: 'red', 
        fontSize: '0.9em', 
        marginTop: '10px'
      }}>
        {CouponMessage}
      </p>
    )}
  </div>
)}


                {couponApplied && (
                  <Button variant="outlined" fullWidth
                    style={{
                      borderColor: 'rgb(209, 38, 63)',
                      fontWeight: 'bold',
                      marginBottom: '20px',
                    }}
                    onClick={handleRemoveCoupon}>
                    Remove Coupon
                  </Button>
                )}
      </div>
    </div>

          <div className="col-md-3 mt-5">
            <hr style={{ border: '1px solid black', margin: 0 }} />
            <hr style={{ border: '1px solid black', margin: 0 }} />
            <div className="accordion-container">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Price Transparency
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Your price details and breakdown</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Returns
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Return policy details</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Delivery
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Delivery and shipping details</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      </div>
    )}
    

    </>
  );
};

export default Payment;
