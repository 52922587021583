import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import successImage from './success.jpg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('./background.jpg') center/cover no-repeat; /* Add background image */
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    z-index: 1;
  }
`;

const Card = styled.div`
  position: relative;
  z-index: 2;
  max-width: 500px;
  background: white;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const ImageWrapper = styled.div`
  margin-bottom: 20px;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Rounded image */
    border: 4px solid #00b4d8;
    padding: 4px;
  }
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.1em;
  color: #666;
  margin-top: 10px;
  line-height: 1.4;
`;

const ProgressBar = styled.div`
  width: 100%;
  background: #eee;
  border-radius: 8px;
  height: 10px;
  margin: 20px 0;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    background: linear-gradient(90deg, #00b4d8, #0077b6);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    animation: fillProgress 1.5s ease-in-out forwards;
  }

  @keyframes fillProgress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
`;

const Button = styled.button`
  background-color: #00b4d8;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0077b6;
    transform: translateY(-2px);
  }
`;

const OrderConfirmation = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/'); // Navigate to the homepage or another route
  };

  return (
    <Container>
      <Card>
        <ImageWrapper>
          <img src={successImage} alt="Success" />
        </ImageWrapper>
        <Title>Thank You for Your Order!</Title>
        <Subtitle>Your order has been placed successfully and is being processed.</Subtitle>
        <ProgressBar />
        <Button onClick={handleNavigate}>Continue Shopping</Button>
      </Card>
    </Container>
  );
};

export default OrderConfirmation;
