import React, { useState, useEffect } from "react";
import { Container, TextField, Select, MenuItem, Grid, Card, CardContent, Typography, Box, Pagination, Button, Collapse, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Navbar from './Navbar';

const OrdersPage = () => {
  const { authToken } = useAuth();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [expandedOrderDetails, setExpandedOrderDetails] = useState({});
  const [loadingDetails, setLoadingDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-order-history", {
          method: "GET",
          headers: {
            'auth-token': authToken,
          },
        });

        const data = await response.json();
        if (data.status === "1") {
          setOrders(data.details);
        } else {
          console.error("Failed to fetch orders:", data.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [authToken]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const fetchOrderDetails = async (orderId) => {
    if (expandedOrderDetails[orderId]) {
      setExpandedOrderDetails((prev) => ({ ...prev, [orderId]: null }));
      return;
    }

    setLoadingDetails((prev) => ({ ...prev, [orderId]: true }));
    try {
      const response = await fetch(`https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-order-details?orderId=${orderId}`, {
        method: "GET",
        headers: {
          'auth-token': authToken,
        },
      });

      const data = await response.json();
      if (data.status === "1") {
        setExpandedOrderDetails((prev) => ({ ...prev, [orderId]: data.details }));
      } else {
        console.error("Failed to fetch order details:", data.message);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      setLoadingDetails((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  const filteredOrders = orders
    .filter((order) => order.orderId.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((order) => (statusFilter ? order.status === statusFilter : true))
    .slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Navbar />
      <Container sx={{ marginTop: 9 }}>
        <Typography variant="h4" gutterBottom>
          Your Orders
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Search Orders"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ marginRight: 2, flexGrow: 1 }}
          />

        </Box>
        <Grid container spacing={2}>
          {filteredOrders.map((order) => (
            <Grid item xs={12} key={order.id}>
              <Card sx={{ border: "1px solid black", borderRadius: 2, marginBottom: 2 }}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography variant="h6" fontWeight="bold">
                        Order ID: {order.orderId}
                      </Typography>
                      <Typography variant="body2">
                        Order Date: {order.order_date}
                      </Typography>
                      <Typography variant="body2">
                        Quantity: {order.quantity}
                      </Typography>
                    </Box>
                    <Box textAlign="right">
                      <Typography variant="h6" fontWeight="bold">
                        {order.currency}{order.order_value}
                      </Typography>
                      <Typography variant="body2">
                        Status:{" "}
                        {order.delivered_date ? (
                          `Delivered on ${order.delivered_date}`
                        ) : (
                          "Yet to be delivered"
                        )}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#d1ad9f", color: "white", marginTop: 1 }}
                        onClick={() => fetchOrderDetails(order.orderId)}
                      >
                        {expandedOrderDetails[order.orderId] ? "Hide Details" : "View Details"}
                      </Button>
                    </Box>
                  </Box>
                  <Collapse in={!!expandedOrderDetails[order.orderId]} timeout="auto" unmountOnExit>
                    {loadingDetails[order.orderId] ? (
                      <Box display="flex" justifyContent="center" p={2}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      expandedOrderDetails[order.orderId] && (
                        <Box mt={2}>
                          <Typography variant="h6">Order Items:</Typography>
                          {expandedOrderDetails[order.orderId].order_details.map((item) => (
                            <Box
                              key={item.id}
                              display="flex"
                              alignItems="center"
                              p={1}
                              sx={{ borderTop: "1px solid #ddd" }}
                            >
                              <img src={item.image} alt={item.name} style={{ width: 60, height: 60, marginRight: 16 }} />
                              <Box>
                                <Typography variant="body2"><strong>Name:</strong> {item.name}</Typography>
                                <Typography variant="body2"><strong>Size:</strong> {item.size} | <strong>Color:</strong> {item.color}</Typography>
                                <Typography variant="body2"><strong>Quantity:</strong> {item.quantity}</Typography>
                                <Typography variant="body2"><strong>Unit Price:</strong> {item.currency}{item.unit_price}</Typography>
                                <Typography variant="body2"><strong>Total Price:</strong> {item.currency}{item.total_price}</Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )
                    )}
                  </Collapse>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={Math.ceil(orders.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
          sx={{ marginTop: 3 }}
        />
      </Container>
    </>
  );
};

export default OrdersPage;
