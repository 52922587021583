import React, { useState } from "react";
import axios from "axios";
import "./ResetPassword.css"; // Create a CSS file for styling

const RequestPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Clear any previous messages

    try {
      const response = await axios.get(
        `https://sharmasoftwaresolutions.com/karen_peach/index.php/user-forgot-password`,
        {
          params: { email },
        }
      );

      if (response.data.status === "1") {
        setStatus("success");
        setMessage("Email has been sent successfully!");
      } else {
        setStatus("error");
        setMessage("User not found.");
      }
    } catch (error) {
      setStatus("error");
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2>Reset Password</h2>
        <p>Enter your registered email to reset your password.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Send Reset Link</button>
        </form>
        {message && (
          <div className={`message ${status}`}>
            <p>{message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestPassword;
