import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button, Input, Form, notification, Typography } from "antd";
import axios from "axios";
import "./ResetPassword.css";
import logo from "../img/Logo.png";

const { Title, Text } = Typography;

const ResetPassword = () => {
  const [searchParams] = useSearchParams(); // React Router hook to access query params
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Get the "i" query parameter
  const uniqueId = searchParams.get("i");

  const handleFinish = async (values) => {
    const { password, confirmPassword } = values;

    if (!uniqueId) {
      notification.error({
        message: "Invalid Link",
        description: "The reset password link is missing or invalid.",
      });
      return;
    }

    if (password !== confirmPassword) {
      notification.error({
        message: "Passwords do not match!",
        description: "Please ensure both password fields are identical.",
      });
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("password", password);
      formData.append("i", uniqueId);

      await axios.post(
        "https://sharmasoftwaresolutions.com/karen_peach/index.php/user-change-password",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      notification.success({
        message: "Password Reset Successfully",
        description: "You can now log in with your new password.",
      });

      navigate("/login");
    } catch (error) {
      notification.error({
        message: "Reset Failed",
        description: error.response?.data?.message || "Something went wrong.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="company-logo" />
        </div>
        <Title level={3} className="title">
          Reset Your Password
        </Title>
        <Text className="description">
          Enter and confirm your new password below to reset your account.
        </Text>
        <Form onFinish={handleFinish} layout="vertical" className="form">
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please enter your new password!" },
              { min: 8, message: "Password must be at least 8 characters long!" },
            ]}
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your new password!" },
            ]}
          >
            <Input.Password placeholder="Confirm your password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="submit-button"
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
